<template>
    <div class="vx-card p-8 sm:w-full md:w-2/5">
        <vs-input
            :label="$t('apps.registered-users.username')"
            type="text"
            class="w-full border-grey-light"
            v-model="internalUser.username"
            :required="true"
            :disabled="isEdit"
        />
        <div class="mt-1" v-if="getFieldErrors('username').length > 0">
            <p v-for="error in getFieldErrors('username')" class="m-0 text-danger text-xs" :key="error.message">{{ error.message }}</p>
        </div>

        <vs-input
            :label="$t('apps.registered-users.email')"
            type="email"
            class="mt-4 w-full border-grey-light"
            v-model="internalUser.email"
            :required="true"
            :disabled="isEdit"
        />
        <div class="mt-1" v-if="getFieldErrors('email').length > 0">
            <p v-for="error in getFieldErrors('email')" class="m-0 text-danger text-xs" :key="error.message">{{ error.message }}</p>
        </div>

        <vs-input
            :label="$t('auth.login.input.password')"
            type="password"
            class="mt-4 w-full border-grey-light"
            v-model="internalUser.password"
            :required="true"
        />
        <vs-input
            type="password"
            class="mt-2 w-full border-grey-light"
            v-model="internalUser.password_confirmation"
            :required="true"
        />
        <div class="mt-1" v-if="getFieldErrors('password').length > 0">
            <p v-for="error in getFieldErrors('password')" class="m-0 text-danger text-xs" :key="error.message">{{ error.message }}</p>
        </div>

        <vs-select
            :label="$t('apps.bannerspaces.form.typeinput')"
            v-model="internalUser.type"
            class="mt-4 w-full border-grey-light"
            :disabled="isEdit"
        >
            <vs-select-item v-for="typeOption in typeOptions" :key="typeOption.key" :value="typeOption.key" :text="typeOption.value"/>
        </vs-select>

        <vs-checkbox
            v-if="internalUser.type === 'admin'"
            class="mt-4 w-full border-grey-light"
            v-model="internalUser.can_impersonate"
        > <p>{{ $t('admin.users.form.can_impersonate') }}</p>
        </vs-checkbox>

        <vs-input
            :label="$t('admin.users.form.firstname')"
            type="text"
            class="mt-2 w-full border-grey-light"
            v-model="internalUser.firstname"
            :required="true"
        />
        <div class="mt-1" v-if="getFieldErrors('firstname').length > 0">
            <p v-for="error in getFieldErrors('firstname')" class="m-0 text-danger text-xs" :key="error.message">{{ error.message }}</p>
        </div>

        <vs-input
            :label="$t('admin.users.form.lastname')"
            type="text"
            class="mt-2 w-full border-grey-light"
            v-model="internalUser.lastname"
            :required="true"
        />
        <div class="mt-1" v-if="getFieldErrors('lastname').length > 0">
            <p v-for="error in getFieldErrors('lastname')" class="m-0 text-danger text-xs" :key="error.message">{{ error.message }}</p>
        </div>

        <vs-select
            :label="$t('apps.registered-users.language')"
            v-model="internalUser.language"
            class="mt-4 w-full border-grey-light">
            <vs-select-item v-for="language in spotlioLanguages" :key="language.acronym" :value="language.acronym" :text="language.name"/>
        </vs-select>

        <vs-select
            :label="$t('profile.label.timezone')"
            v-model="internalUser.timezone"
            class="mt-4 w-full border-grey-light">
            <vs-select-item v-for="timezone in timezones" :key="timezone" :value="timezone" :text="timezone"/>
        </vs-select>

        <div class="flex flex-row gap-4 mt-12">
            <button class="sk-btn sk-btn-primary rounded-lg btn-border" @click="handleCancel">
                {{ $t('apps.modals.cancel')}}
            </button>
            <button class="sk-btn sk-btn-primary rounded-lg" @click="handleSave" :disabled="formErrors.length > 0">
                {{ $t('Save')}}
            </button>
        </div>
    </div>
</template>

<script>

import timezones from '@/timezones.json'
import loader from "@/modules/Shared/Mixins/loader";
import {mapGetters} from "vuex";
import sha256 from "crypto-js/sha256";
import { v4 as uuidv4 } from 'uuid';
import UsersService from "@/modules/Admin/Services/UsersService";

export default {
  name: "UserForm",
  mixins: [loader],
  props: {
      user: {
          type: Object,
          required: true,
      },
      isEdit: {
          type: Boolean,
          default: false
      }
  },
  data() {
      return {
          typeOptions: [
              { key: 'admin', value: 'Admin' },
              { key: 'editor', value: 'Editor' },
          ],
          timezones: timezones,
          internalUser: null,
          formErrors: [],
      }
  },
  created() {
      this.internalUser = {...this.user}
  },
  watch: {
      internalUser: {
          deep: true,
          handler: function () {
              if (this.internalUser.type !== 'admin') {
                  this.internalUser.can_impersonate = false
              }
              this.performValidatons()
          }
      }
  },
  computed: {
      ...mapGetters(['spotlioLanguages']),
  },
  methods: {
    getFieldErrors(field) {
        return this.formErrors.filter(error => error.field === field)
    },
    performValidatons() {
        this.formErrors = []

        if (this.internalUser.password !== this.internalUser.password_confirmation) {
            this.formErrors.push({ field: 'password', message: 'Passwords do not match' })
        }

        if (this.internalUser.password && this.internalUser.password.length < 8) {
            this.formErrors.push({ field: 'password', message: 'Password must be at least 8 characters' })
        }

        if (!this.internalUser.firstname) {
            this.formErrors.push({ field: 'firstname', message: 'Firstname is required' })
        }

        if (!this.internalUser.lastname) {
            this.formErrors.push({ field: 'lastname', message: 'Lastname is required' })
        }

        if (!this.isEdit) {
            if (!this.internalUser.password || !this.internalUser.password_confirmation) {
                this.formErrors.push({ field: 'password', message: 'Password is required' })
            }

            if (!this.internalUser.username) {
                this.formErrors.push({ field: 'username', message: 'Username is required' })
            }

            if (!this.internalUser.email) {
                this.formErrors.push({ field: 'email', message: 'Email is required' })
            }

            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.internalUser.email)) {
                this.formErrors.push({ field: 'email', message: 'Invalid email' })
            }
        }
    },
    handleCancel() {
        this.$router.push({ name: 'admin-users' });
    },
    async handleSave() {
        await this.loadAndNotify(async () => {
            let response;

            const payload = {
                firstname: this.internalUser.firstname,
                lastname: this.internalUser.lastname,
                email: this.internalUser.email,
                can_impersonate: this.internalUser.can_impersonate,
                language: this.internalUser.language,
                timezone: this.internalUser.timezone
            }

            if (this.internalUser.password) {
                payload.password = this.internalUser.password
                payload.password_confirmation = this.internalUser.password_confirmation
            }

            if (this.isEdit) {
                response = await UsersService.editUser(this.internalUser.uuid, payload)
            } else {
                payload.type = this.internalUser.type
                payload.username = this.internalUser.username

                response = await UsersService.createUser(sha256(uuidv4()), payload)
            }

            if (response.code !== 200) {
                return
            }

            await this.$router.push({ name: 'admin-users' });
        }, false)
    }
  }
};
</script>
